import axios from "axios";
import Cookies from "js-cookie";
const handleLogout = async () => {
    try {
      // const response = await axios.post('/auth/logout',{
      //   data: {
      //       token: Cookies.get('mgltoken')
      //     },
      // });
      Cookies.set('mgltoken', null, { expires: 7, domain: process.env.REACT_APP_DOMAIN });
      Cookies.remove('mgltoken', { path: '/' }); // Add path if needed
      window.location.href = "/"
    } catch (error) {
      console.log(error)
    }
};

export default handleLogout